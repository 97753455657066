<template>
    <el-card class="box-card" shadow="never" style="margin:5px 0">
        <el-row :gutter="5" type="flex" justify="center" align="middle">
            <el-col :span="3">已选择 {{soundsSelection?.length}} 条</el-col>
            <el-col :span="3">批量：</el-col>
            <el-col :span="5"><el-button size="small" @click="setKeywords()" v-show="soundsSelection?.length>0">设置关键词</el-button></el-col>
        </el-row>

        <el-row :gutter="2" align="middle" type="flex">
            <el-col :span="24">
                <el-input
                    :clearable="true"
                    type="textarea"
                    :rows="5"
                    placeholder="请输入关键词"
                    maxlength="400"
                    show-word-limit
                    :autosize="{ minRows: 2, maxRows: 7 }"
                    v-model="form.discription">
                </el-input>
            </el-col>
        </el-row>

    </el-card>

</template>

<script>
import apis from "@/api/index"
export default {
    name: 'setkeywords',
    data(){
        return {
            form:{
                discription:'',

            },
            soundsSelection:this.$route.params.sounds
        }
    },
    mounted(){
        //接收router传参
        console.log(this.$route.params.sounds)
    },
    methods:{
        
        setKeywords () {
            let op = {
                sounds_id_arr:this.soundsSelection.map(v=>{return v.id}),
                discription:this.form.discription
            }

            apis.sounds.setSoundsDiscription( op ).then(res=>{
                console.log('res',res)
                if(res.data.code == 1){
                    this.$message({message:"设置成功", type:"success"})
                    this.$router.push({name:'soundsList'})
                }else{
                    this.$message({message:res.data.msg,type:'error'})
                }
            }).catch(err=>{
                this.$message({message:err,type:'error'})
                console.log('err',err)
            })
        }
    },
    created(){
        
    }
}
</script>

<style>

</style>